<template>
  <header class="mx-auto px-4 sm:px-6 md:px-8">
    <h1 class="text-2xl font-semibold text-oakwood-gray-900">
      Enhanced Data Feed Manager
    </h1>
    <p>version {{ version }}</p>
  </header>
  <section class="grid grid-cols-1 lg:grid-cols-6 gap-5 mt-12 px-5">
    <h2 class="sr-only">Available Sections</h2>

    <router-link :to="{name: route.name}" v-for="route,i in routes" :key="`menu-${i}`" >
      <article class="flex flex-col items-center justify-center gap-4 aspect-square bg-white rounded hover:bg-oakwood-blue-50 transition-colors delay-50 ease-in">
        <div class="text-4xl text-white bg-oakwood-blue-500 p-5 rounded-full w-20 h-20 text-center">
          <span class="far" :class="route.meta.icon"></span>
        </div>
        <h3 class="text-oakwood-blue-700 font-bold text-lg">{{ route.meta.title }}</h3>
      </article>
    </router-link>
  </section>
</template>
  
<script>
import { useAuthStore } from '@/stores/auth';
import { mapState } from 'pinia';

export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    routes() {
      return this.$router.getRoutes()
        .filter(route => {
          return route?.meta?.menu === true && route.name != 'dashboard' && this.user.level >= route.meta.acl
        })
        .sort((a,b) => a.meta?.order > b.meta?.order ? 1 : -1);
    }
  },
  methods: {

  },
  components: {
    
  },
}
</script>
  