<template>
    <ModalDialog :open="open" @close="(e) => $emit('close', e)">
        <template #header>
            <h2 class="text-lg leading-6 font-medium text-oakwood-gray-900" id="modal-headline">
                Upload Attachments
            </h2>
        </template>
        <template #body>
            <div class="mt-6">
                <h3 class="text-sm leading-5 font-medium text-oakwood-gray-700">
                    Photos
                </h3>
                <div class="text-sm text-oakwood-gray-600">Total Size: {{ format }} <span v-if="size > checkLimit(limit)" class="inline-flex text-sm bg-oakwood-red-500 px-2 text-white leading-5 rounded-full">Over Limit</span></div>
                <div class="mt-2 flex flex-col gap-2 items-center">
                    <ul :class="attachments.length > 0 ? 'md:grid-cols-2 lg:grid-cols-4' : 'grid-cols-1'" class="grid gap-2">
                        <li v-for="item,i in attachments"
                            :key="`selection-${i}`"
                            class="flex flex-col gap-4 bg-oakwood-gray-100 rounded-lg p-4">
                            <p class="flex justify-between">
                                <span v-if="checkLimit(single) < item.file.size" class="inline-block text-sm bg-oakwood-red text-white leading-5 px-2 rounded-full">Over upload limit</span>
                                <span @click="removeItem(i)" class="far fa-close cursor-pointer self-end"></span>
                            </p>

                            <figure class="w-full aspect-square overflow-hidden bg-oakwood-gray-200 lg:aspect-none group-hover:opacity-75">
                                <img
                                    :src="getImageURL(item)"
                                    class="h-full w-full object-cover object-center"
                                    :alt="item.caption" :title="item.alt" />
                            </figure>
                            <div class="text-oakwood-gray text-sm">
                                <strong>File:</strong> {{ item.file.name }}
                            </div>
                            <div>
                                <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A simple identifier for this image">
                                    Public Title <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                </label>
                                <input type="text" placeholder="Like 'Kitchen' or 'Elevation A'" :id="`alt_${i}`" v-model="item.alt" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div>
                                <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A short description of what the image shows">
                                    Public Caption <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                </label>
                                <textarea :id="`alt_${i}`" placeholder="Like 'A view of the kitchen showing the eating area'" v-model="item.caption" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                            </div>
                            <div>
                                <label :for="`alt_${i}`" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="Search values that will help find this image">
                                    Keywords
                                </label>
                                <textarea :id="`alt_${i}`" placeholder="Other special search words that are not public-facing" v-model="item.meta.keywords" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                            </div>
                            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:pt-5">
                                <label :for="`position_${i}`" class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                                    View <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                                </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                        <select id="position" v-model="item.meta.position"
                                            class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                            <option :value="null">Please Select...</option>
                                            <option v-for="position, k in positions" :key="`position-${k}`" :value="position">{{
                                                position }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            @drop.prevent="drop"
                            class="p-8 border border-dashed border-oakwood-gray-100 text-oakwood-gray-600 w-full flex flex-col items-center justify-center text-center">
                            <span class="far fa-images text-6xl mb-8"></span>
                            <p><label for="upload" class="cursor-pointer underline text-oakwood-blue hover:text-oakwood-blue-600">Select images</label> or drop images here.</p>
                            <p class="text-oakwood-gray-400 text-sm">PNG, JPG, WEBP and SVG not to exceed {{single}}MB each ({{limit}}MB total).</p>
                            <input id="upload" @change="(e) => { upload(e.target.files); }" type="file" multiple="true" accept="image/png,image/jpeg,image/webp,image/svg+xml" class="sr-only">
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #buttons>
            <FormButton type="button" @click="close" icon="fa-cancel"
                class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                Cancel
            </FormButton>
            <FormButton @click="save" :disabled="!valid" icon="fa-arrow-up-from-bracket"
                :class="valid ? 'bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700' : 'bg-oakwood-blue-100 text-white'">Upload</FormButton>
        </template>
    </ModalDialog>
</template>

<script>
    import axios from "axios";
    import ModalDialog from "./ModalDialog.vue";
    import FormButton from "@/components/form/FormButton";
    import {positions} from '@/helpers/globals.js';
    import Attachment from '@/models/attachment';

    export default {
        emits: [
            'save',
            'close'
        ],
        data() {
            return {
                attachments: [],
                positions,
                limit: 64, // MB
                single: 2 // MB
        };
        },
        methods: {
            checkLimit(number) {
                return number * 1024 * 1024;
            },
            getImageURL(item) {
                return URL.createObjectURL(item.file);
            },
            removeItem(i) {
                this.attachments.splice(i,1);
            },
            drop(data) {
                if(data.dataTransfer.files.length) {
                    this.upload(data.dataTransfer.files);
                }
            },
            upload(files) {
                if(files.length)
                    this.attachments = [
                        ...this.attachments,
                        ...Array.from(files).map(file => {
                            return {
                                ...Attachment,
                                file,
                                type: 'URL'
                            }
                        })
                    ];
            },
            save() {
                if(this.attachments.length > 0)
                    axios.postForm(`attachments/bulk`, {uploads: this.attachments})
                        .then(resp => {
                            resp.data = resp.data.map((attachment,i) => {
                                return {
                                    id: attachment.id,
                                    meta: {
                                        type: this.attachments[i].type
                                    }
                                };
                            })
                            this.$emit('save', resp.data);
                            this.close();
                        })
                        .catch(error => {
                            console.error(error);
                        });
            },
            close() {
                this.attachments = [];
                this.$emit('close');
            }
        },
        computed: {
            size() {
                return this.attachments.reduce((prev,curr) => {
                    return prev + curr.file.size
                }, 0);
            },
            format() {
                let size = this.size,
                suffix = 'B';

                if(size > (1024*1024)) {
                    size = size / 1024 / 1024;
                    suffix = ' MB';
                } else if(size > 1024) {
                    size = size / 1024;
                    suffix = ' KB';
                }
                return new Intl.NumberFormat('en-US').format(size) + suffix;
            },
            valid() {
                if (this.attachments.length == 0) return false;
                if (this.size > this.checkLimit(this.limit)) return false;
                if (this.attachments.map(item => {
                    return item.alt !== '' 
                        && item.caption !== ''
                        && item.file.size < this.checkLimit(this.single)
                        && item.meta.position !== null;
                }).includes(false)) {
                    return false;
                }
                return true;
            }
        },
        props: {
            open: {
                default: false
            },
            context: {
                default: false
            }
        },
        components: {
            ModalDialog,
            FormButton
        },
        
    };
</script>
  