export const formatToCurrency = (value) => {
    if (typeof value !== 'number') {
      return value;
    }
  
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  
    return formatter.format(value);
};
  