import { defineStore } from 'pinia';

const query = {
    search: '',
    status: ['new','active'],
    state: null,
    community: null,
    collection: null
  }

export const useMarketHomeStore = defineStore('marketHome', {
    state: () => {
        return {
            query: {...query}
        }
    },
    getters: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query)
        }
    },
    actions: {
        set(values) {
            this.query = values;
        },
        clear() {
            this.query = {...query}
        }
    }
})