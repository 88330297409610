<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Floorplans</h2>
    </div>
    <PaginationTable :data="filtered" class="flex flex-col mt-8">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Floorplan
            </th>
            <th v-if="filtered[0]?.collection"
                scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Collection
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Sales Status
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider lg:w-1/5">
                Status
            </th>
        </template>
        <template #columns="{row}">
            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'floorplan-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ row.meta?.info?.headline || row.kova?.MarketingName }}
                </router-link>
            </td>

            <td v-if="row?.collection" class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                {{ row.collection?.meta?.info?.name || row.collection?.kova?.MarketingName }}
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'floorplan-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="['Pre-Sale (VIP)', 'Off Market', 'Sold Out', 'Internal', '', 'N/S'].includes(row.meta?.info?.status) ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800'">
                        {{ row.meta?.info?.status }}
                    </span>
                </router-link>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900 flex items-center gap-2">
                <ToggleButton :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                :label="row.status == 'active' ? 'Published' : 'Unpublished'"/>
                <label :for="`item-${row.id}-status`">{{ statuses.find(status => status.value == row.status).label }}</label>
            </td>
        </template>
    </PaginationTable>
       
</template>

<script>
import axios from 'axios';
import {ToggleButton} from '@/components/form';
import PaginationTable from '@/components/PaginationTable';
import { statuses } from '@/helpers/globals';

export default {
    data() {
        return {
            statuses
        }
    },
    emits: [
        'update'
    ],
    props: {
        floorplans: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        save(item) {
            item.status = item.status != 'active' ? 'active' : 'inactive';
            axios.patch(`floorplans/${item.id}`, item)
                .then(res => {
                    if(res.data?.id) {
                        this.$emit('update',res.data);
                    }
                })
                .catch(e => {
                    throw e;
                });
        }
    },
    computed: {
        filtered() {
            return this.floorplans;
        }
    },
    components: {
        PaginationTable,
        ToggleButton
    }
}
</script>@/components/form