export default {
    id: null,
    type: null,
    filename: null,
    path: null,
    alt: null,
    caption: null,
    meta: {
        keywords: null,
        position: null
    },
    created_at: null,
    updated_at: null,
    deleted_at: null
}