<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Sales Team</h2>
        <div class="flex gap-2">
            <FormButton icon="fa-link-simple" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button" @click="openSync()">
                Attach
            </FormButton>
            <FormButton icon="fa-plus-circle" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button" @click="edit(agents.length)">
                Create
            </FormButton>
        </div>
    </div>
    <PaginationTable v-if="agents" :data="agents">
        <template #headers>
            <!-- <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Position
            </th> -->
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Name
            </th>
            <th scope="col" class="px-6 py-3 bg-oakwood-gray-50"></th>
        </template>
        <template #columns="{row,i}">
            <!-- <td class="px-6 py-4 whitespace-no-wrap flex gap-2">
                <div class="text-sm leading-5 text-oakwood-gray-900 mr-2">{{ i + 1 }}
                </div>
                <button type="button" :disabled="i == 0" @click="move(agent, 'up')"
                    :class="{ 'cursor-pointer': i > 0, 'text-oakwood-gray-300 cursor-not-allowed': i == 0 }"><span
                        class="far fa-arrow-up"></span></button>
                <button type="button" :disabled="i == agents.length - 1"
                    @click="move(agent, 'down')"
                    :class="i < agents.length - 1 ? 'cursor-pointer' : 'text-oakwood-gray-300 cursor-not-allowed'"><span
                        class="far fa-arrow-down"></span></button>
            </td> -->
            
            <td class="px-6 py-4 whitespace-no-wrap">
                <p class="flex gap-2 items-center">
                    <span class="block rounded-full w-10 h-10 border border-oakwood-gray-100 aspect-square overflow-hidden flex items-center justify-center bg-oakwood-blue-400 text-white">
                        <img v-if="row?.photo" :src="row.photo" />
                        <span v-else class="fas fa-user"></span>
                    </span>
                    <span class="text-sm leading-5 font-medium text-oakwood-gray-900">
                        {{ row?.first_name }} {{ row?.last_name }}
                    </span>
                </p>
            </td>

            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                <FormButton type="button" @click="edit(i)" icon="fa-pencil" class="text-white bg-oakwood-blue-600 hover:bg-oakwood-blue-700">
                    Edit
                </FormButton>
                <FormButton type="button" @click="openDelete(i)" icon="fa-link-simple-slash" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                    Detach
                </FormButton>
            </td>
        </template>
    </PaginationTable>

    <ModalDialog :open="modal" @close="cancel">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                Sales Team Member Editor
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                Enter the details about sales team member you wish to include with this collection.
            </p>
        </template>

        <template #body>

            <div class="mt-6 sm:mt-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <p class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Photo
                    </p>
                    <UploadFiles @attach="attach" v-model="photo" :multiple="false" class="col-span-2" />
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="first_name"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        First Name <span class="text-oakwood-red" title="Value is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="first_name" v-model="agent.first_name"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="last_name"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Last Name <span class="text-oakwood-red" title="Value is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="last_name" v-model="agent.last_name"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="last_name"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Email
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input type="email" id="last_name" v-model="agent.email"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #buttons>
            <div class="flex justify-end mt-5 pt-5">
                <span class="inline-flex rounded-md shadow-sm">
                    <FormButton type="button" @click="cancel" icon="fa-cancel" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                        Cancel
                    </FormButton>
                </span>
                <span class="ml-3 inline-flex rounded-md shadow-sm">
                    <FormButton :disabled="!valid" type="button" @click="save" icon="fa-save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-500">
                        Save
                    </FormButton>
                </span>
            </div>
        </template>
    </ModalDialog>

    <ModalDialog v-if="syncing" :open="syncing" @close="cancel">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                Attach an Existing Sales Team Member
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                Select one or more sales team members to attach.
            </p>
        </template>

        <template #body>
            <ul class="grid grid-cols-2 sm:grid-cols-6 gap-4" v-if="available_agents.length">
                <li v-for="agent,i in available_agents" :key="i" class="rounded-lg overflow-hidden"
                    :class="!sync_agents.includes(agent) ? 'border border-oakwood-gray-100 bg-white' : 'border-2 border-oakwood-blue-600 bg-oakwood-blue-50'">
                    <label :for="`agent-${i}`" class="p-6 flex flex-col gap-2">
                        <figure aria-hidden="true" class="rounded-full overflow-hidden bg-oakwood-blue-400 text-white flex items-center justify-center">
                            <img v-if="agent.photo" :src="agent.photo" class="aspect-square" />
                            <span v-else class="far fa-user"></span>
                        </figure>
                        <p class="text-center text-oakwood-gray-900 font-medium">{{ agent.first_name }} {{ agent.last_name }}</p>
                    </label>
                    <input class="sr-only" type="checkbox" :id="`agent-${i}`" v-model="sync_agents" :value="agent" />
                </li>
            </ul>
            <div v-else class="w-full max-w-xl text-sm text-oakwood-gray-900 p-6 border border-oakwood-gray-100 bg-oakwood-gray-50">
                There are no available sales team members to attach.
            </div>

        </template>

        <template #buttons>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
            <FormButton :disabled="sync_agents.length == 0" type="button" @click="sync" icon="fa-link-simple" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700">
                Attach
            </FormButton>
        </template>
    </ModalDialog>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-blue-600 font-semibold">Confirm Sales Team Member Detachment</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900"><strong>Attention!</strong> You are about to detach <em>{{ agents[index]?.first_name }} {{ agents[index]?.last_name }}</em>. Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="drop" icon="fa-link-simple-slash" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Detach
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import {FormButton, UploadFiles} from '@/components/form';
import ModalDialog from '@/components/ModalDialog';
import PaginationTable from '../PaginationTable';
import axios from 'axios';

const agent = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        photo: null,
    }

export default {
    data() {
        return {
            modal: false,
            index: false,
            agent: {...agent},
            deleting: false,
            syncing: false,
            sync_agents: [],
            available_agents: []
        }
    },
    emits: [
        'update'
    ],
    methods: {
        attach(files) {
            this.agent.photo = files[0];
        },
        // move(agent, direction) {
        //     const agents = this.agents;
        //     let index = agents.indexOf(agent),
        //         to = direction == 'up' ? index - 1 : index + 2,
        //         from = direction == 'up' ? index + 1 : index;



        //     agents.splice(to, 0, agent)
        //     agents.splice(from, 1);
        //     this.$emit('update', agents);
        // },
        edit(index) {
            this.modal = 1;
            this.index = index;
            if(this.agents[index] !== undefined) 
                this.agent = {...this.agents[index]};
        },
        sync() {
            if(!this.sync_agents.length) 
                return;
            let next = this.agents.length;
            this.sync_agents.forEach(async (agent,i) => {
                console.log(`synching ${agent}...`);
                delete agent.photo;
                await axios.patch(`collections/${this.$route.params.id}/agents/${agent.id}`, {...agent, meta: { order: (i + next) }})
                    .then(res => {
                        this.$emit('update', res.data);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            });         
            this.cancel();
        },
        save() {
            let data = {
                ...this.agent,
                meta: {order: this.index}
            };

            console.log(data);
            // don't resend image if it is not a file
            if(['string','null'].includes(typeof data.photo)) {
                delete data.photo;
            }
            let handler = data.id ? 
                axios.postForm(`collections/${this.$route.params.id}/agents/${data.id}?_method=PATCH`, data) : 
                axios.postForm(`collections/${this.$route.params.id}/agents`, data);

            handler
            .then(res => {
                this.$emit('update', [...this.agents,res.data]);
                this.cancel();
            })
            .catch(err => {
                console.error(err);
                this.cancel();
            });
        },
        getAgents() {
            axios.get(`agents`)
                .then(res => {
                    this.available_agents = res.data.filter(agent => !this.agents.map(agent => agent.id).includes(agent.id) && agent.status == 'active');
                })
        },
        drop() {
            if(this.index === false) {
                console.error('You must select a sales agent to delete');
                return;
            }
            axios.delete(`collections/${this.$route.params.id}/agents/${this.agent.id}`)
                .then(res => {
                    this.$emit('update', res.data);
                })
                .catch(err => {
                    console.error(err);
                })
            
            this.cancel();
        },
        openDelete(i) {
            this.index = i;
            this.agent = {...this.agents[i]};
            this.deleting = true;
        },
        openSync() {
            this.getAgents();
            this.syncing = true;
        },
        cancel() {
            this.agent = {...agent};
            this.index = false;
            this.modal = false;
            this.deleting = false;
            this.syncing = false;
        }
    },
    computed: {
        photo: {
            get() {
                return this.agent.photo == null ? [] : [this.agent.photo];
            },
            set(value) {
                this.agent.photo = value[0];
            }
        },
        valid() {
            if(!this.agent.first_name || !this.agent.last_name) {
                console.error('Missing required values');
                return false;
            }
            return true;
        },
    },
    props: {
        agents: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    components: {
        FormButton, ModalDialog, PaginationTable,
        UploadFiles
    }
}
</script>