<template>
    <label v-if="!id" :for="getId" :class="{'sr-only' : !showLabel}" class="block text-sm font-medium leading-5 text-oakwood-gray-700">{{ label }}</label>
    <div class="bg-white border border-oakwood-gray-100 relative rounded-md shadow-sm w-auto inline-block group sm:text-sm sm:leading-5 ">
        <p aria-hidden="true" class="p-2 pr-8">{{ selected }}</p>
        <ul class="hidden group-hover:block bg-transparent pt-1 -mt-1 absolute left-0 top-full z-10 bg-white focus:z-10 transition ease-in-out duration-150 rounded-b-lg rounded-tr-lg overflow-hidden shadow-sm">
            <li v-for="option,i in options" :key="option.value" class="flex items-center hover:bg-oakwood-blue-200 px-2 py-1">
                <input type="checkbox" v-model="value" :id="`${getId}-${i}`" :value="option.value" class="appearance-none far text-bold p-2 fa-check" :class="{'invisible' : !value.includes(option.value)}"> 
                <label :for="`${getId}-${i}`">
                {{ option.label }}
                </label>
            </li>
        </ul>
        <div aria-hidden="true" class="z-0 absolute top-0 right-0 bottom-0 flex items-center justify-end p-2"><span class="far fa-angles-up-down"></span></div>
    </div>
</template>

<script>
export default {
    emits: [
        'update:modelValue'
    ],
    props: {
        id: {
            type: [Boolean, String],
            default: false
        },
        modelValue: {
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        },
        options: {
            default: false
        },
        empty: {
            type: String,
            default: "None Selected..."
        }
    },
    computed: {
        selected() {
            if (this.value.length == 0 || this.value[0] === null || this.value.length == this.options.length) {
                return this.empty;
            } else {
                return this.options ? this.value.map(key => this.options.find(option => option.value == key)?.label).join(', ') : this.value.join(', ')
            }
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        getId() {
            return this.id || `field-${this.$.uid}`;
        }
    },

}
</script>