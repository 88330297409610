<template>
    <LoadingView v-if="isLoading" />
    <header class="flex justify-between items-center">
      <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Surveys</h1>
      <FormButton :to="{name: 'survey-add', }" icon="fa-add" class="bg-oakwood-blue-500 text-white">Add New Survey</FormButton>
    </header>

    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search user names or emails by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
          <SelectField v-model="query.collection" label="Collection">
              <option :value="null">All Collections</option>
              <option v-for="collection,i in collections" :key="`callout-${i}`" :value="collection.id">{{ collection.meta?.info?.name }}</option>
          </SelectField>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>
  
      <nav v-if="!isLoading" class="text-oakwood-gray-700 px-4 py-3 flex items-center justify-between sm:px-6">
        <div class="hidden sm:block">
          <p v-if="filtered.length > 0" class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ from + 1 }}</span>
            to
            <span class="font-medium">{{ to }}</span>
            of
            <span class="font-medium">{{ filtered.length }}</span>
            results.
          </p>
          <p v-else class="text-sm leading-5 text-gray-700">
            No results found.
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <button :disabled="page == 1" @click="setPage('prev')" type="button" :class="{'opacity-50 cursor-not-allowed' : page == 1}" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            Previous
          </button>
          <button :disabled="!max || page == max" @click="setPage('next')" type="button" :class="{'opacity-50 cursor-not-allowed' : !max || page == max}" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            Next
          </button>
        </div>
      </nav>
  
      <div v-if="!isLoading" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-oakwood-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-oakwood-gray-200">
              <thead class="hidden sm:table-header-group">
                <tr>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    File Name
                  </th>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Collection
                  </th>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Created Date
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-oakwood-gray-200">
                <tr class="hover:bg-oakwood-blue-50 grid grid-cols-1 items-center sm:table-row" v-for="item in paginated" :key="item.sales_order_rid">
                  <td class="block sm:table-cell">
                    <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: item.id } }">
                      <div class="flex items-center gap-2">
                        <div class="flex-shrink-0 text-2xl text-oakwood-blue-500">
                          <span class="fas fa-file-xml"></span>
                        </div>
                        <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                          {{ item.filename }}
                        </div>
                      </div>
                    </router-link>
                  </td>
                  <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
                    <router-link class="block px-6 sm:py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: item.id } }">
                        <p class="text-sm leading-5 text-oakwood-gray-900">
                            {{ getCollectionName(item.collection_id) }}
                        </p>
                    </router-link>
                  </td>
                  <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
                    <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: item.id } }">
                    {{ getDate(item.created_at) }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {FormButton,SelectField} from '@/components/form';

const query = {
    search: null,
    collection: null
}

export default {
    data() {
        return {
            isLoading: true,
            query: {...query},
            page: 1,
            limit: 20,
            surveys: [],
            collections: []
        }
    },
    components: {
        LoadingView, FormButton, SelectField
    },
    methods: {
        index() {
            axios.get('surveys')
                .then(resp => {
                    this.surveys = resp.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        indexCollections() {
          axios.get('collections')
                .then(resp => {
                    this.collections = resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        getCollectionName(id) {
          return this.collections.find((collection) => collection.id == id)?.meta?.info?.name ?? ''
        },
        getDate(date) {
          return new Intl.DateTimeFormat('en-US',{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
            timeZone: "America/Denver",
          }).format(new Date(date));
        },
        reset() {
            this.query = {...query};
        }
    },
    computed: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query);
        },
        filtered() {
            let results = this.surveys;
            
            if (this.query.search !== null) {
            const regex = new RegExp(this.query.search, 'i');
            results = results.filter(item => regex.test(item.filename) || 
                regex.test(JSON.stringify(item.meta))
                );
            } 

            if (this.query.collection !== null) {
              results = results.filter(item => item.collection_id == this.query.collection);
            }
            
            return results;
        },
        paginated() {
            return this.filtered.slice(this.from, this.to);
        },
        from() {
            return (this.page * this.limit) - this.limit;
        },
        to() {
            let to = this.page * this.limit;
            return to <= this.filtered.length ? to : this.filtered.length;
        },
        max() {
            return Math.ceil(this.filtered.length / this.limit);
        },
    },
    created() {
      this.index();
      this.indexCollections();
    }
}
</script>