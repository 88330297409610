<template>
    <label v-if="!$attrs.id" :for="getId" :class="{'sr-only' : !showLabel}" class="block text-sm font-medium leading-5 text-oakwood-gray-700">{{ label }}</label>
    <div class="bg-white border border-oakwood-gray-100 relative rounded-md shadow-sm w-auto inline-block" :class="$attrs.class">
        <select :id="getId" v-bind="$attrs" v-model="value" class="appearance-none bg-transparent p-2 pr-8 relative z-10 block rounded-none focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <slot>
                <option :value="null">None Selected...</option>
            </slot>
        </select>
        <div aria-hidden="true" class="z-0 absolute top-0 right-0 bottom-0 flex items-center justify-end p-2"><span class="far fa-angles-up-down"></span></div>
    </div>
</template>

<script>
export default {
    emits: [
        'update:modelValue'
    ],
    props: {
        modelValue: {
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        getId() {
            return this.$attrs.id || `field-${this.$.uid}`;
        }
    },

}
</script>