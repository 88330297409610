import { defineStore } from 'pinia';

const query = {
    search: '',
    status: ['new','active']
  }

export const useCommunityStore = defineStore('community', {
    state: () => {
        return {
            query: {...query}
        }
    },
    getters: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query)
        }
    },
    actions: {
        set(values) {
            this.query = values;
        },
        clear() {
            this.query = {...query}
        }
    }
})