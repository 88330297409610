<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Custom Images</h2>
        <div class="flex justify-end gap-4">
            <FormButton icon="fa-link-simple" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button"
                @click="() => {
                    browser = true
                }">
                Attach
            </FormButton>
            <FormButton icon="fa-arrow-up-from-bracket" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700"
                type="button" @click="() => {
                    uploads = true
                }">
                Upload
            </FormButton>
        </div>
    </div>

    <div v-if="collection.id !== null" class="sm:col-span-2">
        <div v-if="attachments.length > 0"
            class="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
            <div v-for="attachment, i in attachments" :key="`image-${i}`"
                class="group relative border-2 border-oakwood-gray-100 rounded-lg p-5 flex flex-col">
                <div class="sm:block absolute top-2 right-2 px-2 py-px bg-white rounded-full aspect-square z-10">
                    <button type="button" @click="openDelete(attachment)" 
                        class="text-oakwood-gray-400 hover:text-oakwood-gray-500 focus:outline-none focus:text-oakwood-gray-500 transition ease-in-out duration-150"
                        aria-label="Detach this image" title="Detach this image"><span class="fa fa-close"></span>
                    </button>
                </div>
                <div
                    class="aspect-square overflow-hidden bg-oakwood-gray-200 lg:aspect-none group-hover:opacity-75 flex-none">
                    <router-link :to="{name: 'media-edit', params: {id: attachment.id}}" class="cursor-pointer"><img :src="attachment.url"
                        class="h-full w-full object-cover object-center lg:h-full lg:w-full border border-oakwood-gray-50"
                        :alt="attachment.alt" :title="attachment.caption">
                    </router-link>
                </div>
                <div class="mt-4 flex justify-between flex-1">
                    <div>
                        <h3 class="text-sm font-semibold text-oakwood-gray-900">
                            {{ i + 1 }}: {{ attachment.alt }}
                        </h3>
                        <dl>
                            <dt class="sr-only">View</dt>
                            <dd
                                class="bg-oakwood-blue-500 text-white inline-block px-2 leading-5 rounded-full relative bottom-px text-xs">
                                {{ attachment.meta?.position }}</dd>

                            <dt class="sr-only">Caption</dt>
                            <dd class="text-sm text-oakwood-gray-700">
                                {{ attachment.caption }}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="mt-4 flex justify-between flex-none">
                    <button :disabled="i == 0" type="button" class="far fa-arrow-left"
                        :class="i == 0 && 'text-oakwood-gray-200 cursor-not-allowed'"
                        @click="move(attachment, 'up')"></button>
                    <button :disabled="i == attachments.length - 1" type="button" class="far fa-arrow-right"
                        :class="i == attachments.length - 1 && 'text-oakwood-gray-200 cursor-not-allowed'"
                        @click="move(attachment, 'down')"></button>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col gap-4 items-center justify-center py-12 text-oakwood-gray-600">
            <p class="far fa-images text-6xl"></p>
            <p>There are no custom images currently attached to this collection.</p>
        </div>
    </div>

    <ModalDialog :open="browser" @close="() => { this.browser = false }">
        <template #header>
            <div>
                <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                    Custom Image Attachment
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                    Select an image from the media manager and attach it to the current collection.
                </p>
            </div>
        </template>
        <template #body>
            <MediaBrowser :full="false" :media="media" @close="() => { this.browser = false }"
                @attach="(selected) => { this.selected = selected; this.save(); }"></MediaBrowser>
        </template>
    </ModalDialog>

    <AttachmentsUpload :open="uploads" @close="cancel" @save="prepare" :context="collection.id">
    </AttachmentsUpload>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-red-600 font-semibold">Confirm Image Detachment</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900"><strong>Warning!</strong> You are about to detach <em>{{
                image?.alt }}</em>. Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="drop" icon="fa-trash-can-check"
                class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Detach
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel"
                class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import FormButton from '@/components/form/FormButton';
import ModalDialog from '@/components/ModalDialog';
import MediaBrowser from '@/components/MediaBrowser';
import AttachmentsUpload from '@/components/AttachmentsUpload';
import axios from 'axios';

const image = {
    image: {
        id: null,
        path: null,
        alt: null,
        meta: {
            position: null
        }
    },
    type: 'URL'
}

export default {
    data() {

        return {
            attach: false,
            browser: false,
            uploads: false,
            index: false,
            image,
            selected: [],
            type: 'URL',
            deleting: false,
            imageRefresh: false,
            attachments: [],
            media: [],
        }
    },
    methods: {
        move(image, direction) {
            let index = this.attachments.indexOf(image),
                to = direction == 'up' ? index - 1 : index + 2,
                from = direction == 'up' ? index + 1 : index;
            this.attachments.splice(to, 0, image)
            this.attachments.splice(from, 1);
            axios.patch(`collections/${this.collection.id}/attachments`, {
                attachments: this.attachments
                    .map((attachment, i) => {
                        attachment.pivot.meta.order = i;
                        return attachment.pivot;
                    })
            })
                .then(resp => {
                    this.attachments = resp.data;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        list() {
            if (!this.collection?.id) return;
            axios.get(`collections/${this.collection.id}/attachments`)
                .then(resp => {
                    this.attachments = resp.data;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        edit(index) {
            this.browser = true;
            this.index = index;
            this.image = this.collection.meta.images[index];
        },
        prepare(selected) {
            this.selected = selected;
            this.save();
        },
        save() {
            console.log('got to save');
            // prepare new array with existing attachments
            let attachments = [
                ...this.attachments.map(attachment =>
                    attachment.pivot
                ),
            ];
            let next = attachments.length;

            attachments = [
                ...attachments,
                ...this.selected.map((attachment, i) =>
                ({
                    attachment_id: attachment.id,
                    item_id: this.collection.id,
                    item_type: 'sales_order',
                    meta: {
                        type: attachment?.meta?.type || this.type,
                        order: next + i
                    }
                })
                )
            ];

            axios.patch(`collections/${this.collection.id}/attachments`, { attachments })
                .then(resp => {
                    this.attachments = resp.data;
                });
            this.cancel();
        },
        drop() {
            if (this.index === false) {
                console.error('You must select an item to delete');
                return;
            }
            axios.delete(`collections/${this.collection.id}/attachments/${this.image.id}`)
                .then(resp => {
                    this.attachments = resp.data;
                })
            this.cancel();
        },
        openDelete(image) {
            this.index = image.id;
            this.image = image
            this.deleting = true;
        },
        cancel() {
            this.attach = false;
            this.selected = [];
            this.browser = false;
            this.uploads = false;
            this.image = { ...image };
            this.index = false;
            this.deleting = false;
        },
        getAvailableImages() {
            axios.get(`attachments`)
                .then((resp) => {
                    this.media = resp.data.filter(image => !this.attachments.map(attachment => attachment.id).includes(image.id));
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    },
    computed: {
        getItemId() {
            return this.collection.id;
        },
        valid() {
            if(this.selected.length == 0) return false;
            return true;
        }
    },
    created() {
        this.list();
    },
    watch: {
        browser: {
            handler(newVal) {
                if (newVal === true) {
                    this.getAvailableImages();
                }
            }
        }
    },
    props: {
        collection: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        ModalDialog,
        MediaBrowser,
        AttachmentsUpload
    }
}
</script>