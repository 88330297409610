<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded-lg">

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-center justify-between gap-8">
            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                {{ id ? 'Edit User' : 'Add User'}}
            </h1>
            <nav class="flex flex-none gap-4 justify-end items-center order-1 lg:order-2">
                <div class="flex-none">
                    <FormButton 
                        class="bg-oakwood-gray-50 hover:opacity-100 transition-opacity delay-75" 
                        icon="fa-cancel" @click="$router.go(-1)">
                        Cancel
                    </FormButton>
                </div>
                <div>
                    <FormButton @click="update" :disabled="!valid" icon="fa-check" class="bg-oakwood-blue-500 text-white">
                        Save
                    </FormButton>
                </div>
            </nav>
        </header>


        <form autocomplete="off" class="grid grid-cols-1 p-5 gap-5 max-w-4xl">
            <input autocomplete="false" name="hidden" type="text" style="display:none;">
                <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                    <label for="first_name" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                        First Name <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <input type="text" placeholder="First Name" id="first_name" v-model="item.first_name" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                    <label for="last_name" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                        Last Name <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <input type="text" placeholder="Last Name" id="last_name" v-model="item.last_name" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                    <label for="email" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                        Email <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <input type="text" placeholder="Email" id="email" v-model="item.email" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-4 pt-5">
                    <label for="password" class="block text-sm font-medium leading-6 text-oakwood-gray-900">
                        Password <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <input type="password" id="password" v-model="item.password" class="md:col-span-3 p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
                </div>

                <div v-if="user.level > 1" class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-4 pt-5">
                    <label :for="`position_${i}`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                        User Level <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs md:col-span-3">
                        <SelectField id="position" v-model="item.level">
                            <option :value="null">Please Select...</option>
                            <option :value="1">User</option>
                            <option :value="2">Admin</option>
                        </SelectField>
                    </div>
                </div>



        </form>
        
    </article>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton, SelectField } from '@/components/form';
import { useAuthStore } from '@/stores/auth';
import { mapState } from 'pinia';

export default {
    data() {
        return {
            id: null,
            loading: true,
            item: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                level: 1
            }
        }
    },
    methods: {
        show() {
            if(!this.id) return;
            axios.get(`users/${this.id}`)
                .then(resp => {
                    this.item = resp.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        update() {
            if(!this.id) {
                axios.post('users', this.item)
                    .then(() => {
                        this.$router.go(-1);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                let data = {...this.item};
                if(data.password === null) {
                    delete data.password;
                }
                axios.patch(`users/${this.id}`, data)
                    .then(() => {
                        this.$router.go(-1);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user']),
        valid() {
            let required = this.item.first_name && this.item.last_name && this.item.email;
            if(!this.id) required = required && this.item.password;
            if(this.user.level > 1) required = required && this.item.level;
            return required;
        }
    },
    created() {
        // check if the route is user-edit or profile-edit
        this.id = this.$route.name == 'user-edit' ? this.$route.params.id || false : this.user.id;
        if(this.id) {
            this.show();
        } else {
            this.loading = false;
        }
    },
    components: {
        LoadingView, FormButton, SelectField
    }

}
</script>../@/components/LoadingView.vue../@/components/form