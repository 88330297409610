<template>
    <LoadingView v-if="isLoading" />
    <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Floorplans</h1>
    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search floorplans by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
          <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
        </div>
        <div v-if="communities.length" class="mb-4 flex-initial">
          <SelectField v-model="query.community" label="Community">
              <option :value="null">All Communities</option>
              <option v-for="community,i in communities" :key="`callout-${i}`" :value="community">{{ community.meta?.info?.name ?? community.kova.WebGroupName }}</option>
          </SelectField>
        </div>
        <div v-if="collections.length > 1" class="mb-4 flex-initial">
          <SelectField v-model="query.collection" label="Collection">
              <option :value="null">All Collections</option>
              <option v-for="collection,i in collections" :key="`callout-${i}`" :value="collection.community_rid">{{ collection.kova?.MarketingName }}</option>
          </SelectField>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>
  
      <nav v-if="!isLoading" class="text-oakwood-gray-700 px-4 py-3 flex items-center justify-between sm:px-6">
        <div class="hidden sm:block">
          <p v-if="filtered.length > 0" class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ from + 1 }}</span>
            to
            <span class="font-medium">{{ to }}</span>
            of
            <span class="font-medium">{{ filtered.length }}</span>
            results.
          </p>
          <p v-else class="text-sm leading-5 text-gray-700">
            No results found.
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <button :disabled="page == 1" @click="setPage('prev')" type="button" :class="{'opacity-50 cursor-not-allowed' : page == 1}" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            Previous
          </button>
          <button :disabled="!max || page == max" @click="setPage('next')" type="button" :class="{'opacity-50 cursor-not-allowed' : !max || page == max}" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            Next
          </button>
        </div>
      </nav>
  
      <div v-if="!isLoading" class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-oakwood-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-oakwood-gray-200">
              <thead class="hidden sm:table-header-group">
                <tr>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Base Price
                  </th>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
                    Sales Status
                  </th>
                  <th
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
                    Published
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-oakwood-gray-200">
                <tr class="hover:bg-oakwood-blue-50 grid grid-cols-4 sm:table-row" v-for="item in paginated" :key="item.sales_order_rid">
                  <td class="col-span-3 order-1 block sm:table-cell">
                    <router-link class="block px-6 sm:py-4 whitespace-no-wrap" :to="{ name: 'floorplan-show', params: { id: item.id } }">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 rounded-full overflow-hidden">
                          <img v-if="item.image" class="h-10 w-10 aspect-square object-cover"
                            :src="item.image" alt="">
                          <i v-else class="fa-duotone fa-house fa-2xl"></i>
                        </div>
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                            {{ item.meta.info?.name || item.kova?.MarketingName }}
                            <span v-if="item.status == 'new'" class="bg-oakwood-gray-50 text-oakwood-blue-800 px-2 rounded-full inline-block text-xs leading-5 ml-1">New</span>
                          </div>
                          <div class="text-sm leading-5 text-oakwood-gray-500">
                            {{ getCollection(item.collection_id) }}
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </td>
                  <td class="block order-2 col-span-2 sm:table-cell">
                    <router-link class="block pl-20 pr-6 pb-4 sm:px-6 sm:py-4 whitespace-no-wrap" :to="{ name: 'floorplan-show', params: { id: item.id } }">
                      <div class="text-sm leading-5 text-oakwood-gray-900">
                        {{ formatToCurrency(item.meta?.info?.price || item.kova?.Price) }}
                      </div>
                    </router-link>
                  </td>
                  <td class="block order-0 col-span-4 sm:table-cell">
                    <router-link class="block px-6 pt-4 pb-2 sm:py-4 whitespace-no-wrap" :to="{ name: 'floorplan-show', params: { id: item.id } }">
                      <span
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-oakwood-green-100 text-oakwood-green-800"
                        :class="['Internal', 'Off Market', 'Sold Out','N/S'].includes(item.meta.info?.status) ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800' ">
                        {{ floorplanStatuses[item.meta?.info.status] }}
                      </span>
                    </router-link>
                  </td>
                  <td class="sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block order-1 sm:table-cell">
                    <div class="flex items-center gap-2">
                      <ToggleButton :id="`item-${item.id}-status`" @changed="save(item)" :checked="item.status == 'active'" 
                      :label="item.status == 'active' ? 'Published' : 'Unpublished'"/>
                      <label class="sr-only" :for="`item-${item.id}-status`">{{ statuses.find(status => status.value == item.status).label }}</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import axios from "axios";
  import { formatToCurrency } from '@/helpers/currencyHelpers';
  import LoadingView from '@/components/LoadingView.vue';
  import {ToggleButton,SelectField,SelectMultipleField,FormButton} from '@/components/form';
  import { mapStores } from 'pinia';
  import { useFloorplanStore } from "@/stores/floorplans";
  import { statuses, floorplan } from '@/helpers/globals';
  
  export default {
    data() {
      return {
        floorplans: [],
        communities: [],
        page: 1,
        limit: 20,
        isLoading: true,
        statuses,
        floorplanStatuses: floorplan.ModelStatus
      }
    },
    methods: {
      index() {
        this.isLoading = true;
        axios.get(`floorplans`)
          .then(res => {
            this.floorplans = res.data;
            this.isLoading = false;
          })
          .catch(e => {
            throw e;
          });
      },
      indexCommunities() {
        axios
        .get(`divisions/communities`)
        .then((res) => {
          let collection_rids = new Set(this.floorplans.map(floorplan => floorplan.kova.CommunityRID));
          this.communities = res.data
            .map(community => {
              community.collections = community.collections.filter(collection => collection.status != 'inactive' || collection_rids.has(collection.community_rid));
              return community;
            })
            .filter(community => community.status != 'inactive' && community.collections.length > 0)
            .sort((a,b) => (a.meta?.info?.name || a.kova?.WebGroupName) > (b.meta?.info?.name || b.kova?.WebGroupName) ? 1 : -1);
          this.isLoading = false;
        })
        .catch((e) => {
          throw e;
        });
      },
      formatToCurrency,
      save(item) {
        item.status = item.status != 'active' ? 'active' : 'inactive';
        this.isLoading = true;
        axios.patch(`floorplans/${item.id}`, item)
            .then(res => {
                if(res.data?.id) {
                  this.index();
                }
                this.isLoading = false;
            })
            .catch(e => {
                throw e;
            });
      },
      setPage(direction) {
        if(direction == 'next') {
          this.page++;
        } else {
          this.page--;
        }
      },
      reset() {
        this.floorplanStore.reset();
      },
      getCollection() {
        return "";
      }
    },
    computed: {
      ...mapStores(useFloorplanStore),
      query: {
        get() {
          return this.floorplanStore.query;
        },
        set(query) {
          this.floorplanStore.set(query);
        }
      },
      filtered() {
        let results = this.floorplans;
        if (!this.query.status.includes(null)) {
          results = results.filter(item => this.query.status.includes(item.status));
        }
          
        if (this.query.collection !== null) results = results.filter(item => item.kova.CommunityRID == this.query.collection);
  
        if (this.query.community !== null) results = results.filter(item => this.query.community.collections.map(collection => collection.community_rid).includes(item.kova.CommunityRID));
  
        if (this.query.search != '') {
          const regex = new RegExp(this.query.search, 'i');
          results = results.filter(item => regex.test(item.kova?.Name) || 
            regex.test(item.community_model_rid)
            );
        } 
        return results;
      },
      paginated() {
        return this.filtered.slice(this.from, this.to);
      },
      from() {
        return (this.page * this.limit) - this.limit;
      },
      to() {
        let to = this.page * this.limit;
        return to <= this.filtered.length ? to : this.filtered.length;
      },
      max() {
        return Math.ceil(this.filtered.length / this.limit);
      },
      collections() {
        if(this.communities.length == 0 || this.query.community === null) return [];
        return this.query.community.collections;
      },
      hasFilters() {
        return this.floorplanStore.hasFilters;
      }
    },
    watch: {
      'query.community' : {
        handler() {
          this.query.collection = null;
        }
      }
    },
    created() {
      this.index();
      this.indexCommunities();
    },
    components: { LoadingView, ToggleButton, SelectField, SelectMultipleField, FormButton },
  }
  </script>
  
  @/components/form