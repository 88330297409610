<template>
    <div>
        <div v-if="error" class="bg-oakwood-red-50 text-oakwood-red-600 p-4 border border-oakwood-red-600 flex items-center gap-2">
            <span class="far fa-triangle-exclamation"></span>
            {{ error }}
        </div>

        <ul class="grid gap-2" :class="{'grid-cols-2 md:grid-cols-4': value.length >= 2, 'grid-cols-1': value.length < 2}">
            <li v-for="item, i in value" :key="i" class="flex flex-col justify-center items-center gap-2">
                <div v-if="format=='image'" class="w-full aspect-square overflow-hidden bg-oakwood-gray-200 lg:aspect-none group-hover:opacity-75">
                    <img :src="getImageURL(item)" class="h-full w-full object-cover object-center" alt="This is the image you intend to upload.">
                </div>
                <div v-else class="flex flex-col items-center justify-start gap-2 text-center">
                    <p class="far fa-file text-5xl text-oakwood-gray-600"></p>
                    <p class="text-sm">{{ item.name }}</p>
                </div>
                <FormButton @click="remove(i)" icon="fa-swap" class="bg-oakwood-gray-100 hover:bg-oakwood-gray-300">Replace</FormButton>            
            </li>
            <li v-if="(!multiple && value.length == 0) || (multiple && (limit == 0 || value.length < limit))"
                @drop.prevent="drop"
                class="p-8 border border-dashed border-oakwood-gray-100 text-oakwood-gray-600 w-full flex flex-col items-center justify-center text-center"
            >
                <span class="far text-6xl mb-8" :class="format == 'image' ? 'fa-images' : 'fa-files'"></span>
                <p><label for="upload" class="cursor-pointer underline text-oakwood-blue hover:text-oakwood-blue-600">Select {{ multiple ? `${format}s` : format}}</label> or drop {{  multiple ? `${format}s` : format }} here.</p>
                <p class="text-oakwood-gray-400 text-sm">{{listAccepts}} format not to exceed {{maxFile}}MB each and {{ maxUpload }}MB in total.</p>
                <input id="upload" @input="(e) => { upload(e.target.files); }" type="file" :multiple="multiple" :accept="accept.join(',')" class="sr-only">
            </li>
        </ul>
    </div>
</template>

<script>
import {FormButton} from "@/components/form";

export default {
    data() {
        return {
            error: null,
            test: []
        }
    },
    emits: [
        'update:modelValue',
        'error'
    ],
    props: {
        format: {
            type: String,
            default: 'image'
        },
        modelValue: {
            type: Array,
            required: true,
            default: () => []
        },
        limit: {
            type: Number,
            default: 0
        },
        maxUpload: {
            type: Number,
            default: 64
        },
        maxFile: {
            type: Number,
            default: 2
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: Array,
            default: () => [
                "image/png",
                "image/jpeg",
                "image/webp",
                "image/svg+xml"
            ]
        },
        type: {
            type: String,
            default: "URL"
        },
    },
    methods: {
        drop(data) {
            if(data.dataTransfer.files.length) {
                if(!this.multiple && data.dataTransfer.files.length > 1) {
                    this.error = "You may only upload one file"
                    this.$emit('error', this.error);
                } else {
                    this.upload(data.dataTransfer.files);
                }
            }
        },
        upload(files) {
            this.error = null;
            let current = this.value;
            if(files.length)
                if(!this.valid(files)) {
                    this.error = 'One or more files are invalid';
                    return;
                }
                this.value = [
                    ...current,
                    ...files
                ];
        },
        remove(i) {
            let files = this.value;
            delete files[i];
            this.value = files;
        },
        valid(files) {
            let total = 0,
                limitTest = true,
                typesTest = true;
            Array.from(files).forEach(file => {
                if(file.size > this.maxFile * 1024 * 1024) limitTest = false;
                if(!this.accept.includes(file.type)) typesTest = false;
                total += file.size;
            })
            return total < (this.maxUpload * 1024 * 1024) && limitTest && typesTest;
        },
        getImageURL(item) {
            return typeof item == 'string' ? item : URL.createObjectURL(item);
        },
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                console.log(value);
                this.$emit('update:modelValue', value)
            }
        },
        listAccepts() {
            let accepts = this.accept.map(accept => {
                let [,match] = accept.match(/\/([a-z]{3,4})/);
                return match.toUpperCase();
            });
            let last = accepts.pop();
            return accepts.length ? `${accepts.join(', ')} and ${last}` : last;
        }
    },
    components: {
        FormButton
    }
}
</script>
