<template>
    <LoadingView v-if="isLoading" />
    <div v-else class="bg-white shadow sm:rounded-lg">

        <nav 
            class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg" 
            :class="{
                'bg-blue-200' : salesOrder.status == 'new', 
                'bg-green-200' : salesOrder.status == 'active', 
                'bg-oakwood-gray-200' : salesOrder.status == 'inactive'
            }">
            <div class="px-5 flex-none">
                <FormButton 
                    class="shadow-none bg-white opacity-80 hover:opacity-100 transition-opacity delay-75" 
                    :class="{
                        'bg-blue-300': salesOrder.status == 'new',
                        'bg-green-300': salesOrder.status == 'active',
                        'bg-oakwood-gray-300': salesOrder.status == 'inactive'
                    }"
                    icon="fa-chevron-left" :to="{ name: 'available-homes-list' }">
                    Return to List
                </FormButton>
            </div>
            <div class="py-4 px-5 sm:rounded-tr-lg flex justify-end">
                <ToggleButton 
                    id="publish-toggle" 
                    :colors="{
                        on: 'bg-green',
                        off: salesOrder.status == 'new' ? 'bg-blue' : 'bg-oakwood-gray-800',
                        text_on: 'text-green',
                        text_off: salesOrder.status == 'new' ? 'text-blue' : 'text-oakwood-gray-800'
                    }"
                    :checked="salesOrder.status == 'active'" 
                    @changed="changeStatus" label="Set Publishing Status" />
                <label for="publish-toggle"
                    :class="{
                        'bg-blue' : salesOrder.status == 'new', 
                        'bg-green' : salesOrder.status == 'active', 
                        'bg-oakwood-gray-800' : salesOrder.status == 'inactive'
                    }"
                    class="text-white px-2 inline-block text-xs leading-6 font-semibold rounded-full ml-2"
                    v-text="statusLabel"></label>
            </div>
        </nav>
        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gap-8">
            <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ salesOrder.kova?.Lot?.StreetAddress }}, {{ salesOrder.kova?.Lot?.City }},  {{ salesOrder.kova?.Lot?.State }} {{
                                    salesOrder.kova?.Lot?.ZipCode }}
                                    <span v-if="salesOrder.meta.info.callout !== 'N/S'" class="bg-oakwood-gray text-white px-2 rounded-full inline-block text-xs leading-5 relative bottom-1 ml-2">{{ salesOrder.meta?.info?.callout }}</span>
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Model
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ salesOrder.kova?.HouseName }}
                                </dd>
                                <dt class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    Kova ID:
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ salesOrder.kova?.SlsOrdRID }}
                                </dd>
                                <dt class="sr-only">
                                    Name
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    {{ salesOrder.kova?.Name }}
                                </dd>
                                
                            </dl>
                        </div>
                    </div>
                    <section class="flex flex-row flex-wrap border border-oakwood-gray-200 rounded">
                        <h2 class="sr-only">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[salesOrder.kova?.Status == 'Released' ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far"
                                    :class="[salesOrder.kova?.Status == 'Released' ? 'fa-lightbulb-on' : 'fa-lightbulb-slash']"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Kova Status</dt>
                                <dd class="font-semibold">{{ salesOrder.kova?.Status }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-house"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Lot</dt>
                                <dd class="font-semibold">{{ lot }}</dd>
                            </dl>
                        </aside>


                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-circle-dollar"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Price</dt>
                                <dd class="font-semibold">{{ formatToCurrency(salesOrder.meta?.info?.price || salesOrder.kova?.Price) }}</dd>
                            </dl>
                        </aside>


                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-arrows-maximize"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Square Footage</dt>
                                <dd class="font-semibold">{{ new Intl.NumberFormat('en-US',{}).format(salesOrder.meta?.info?.sqft) }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-bed"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Bedrooms</dt>
                                <dd class="font-semibold">{{ salesOrder.meta?.info?.bedrooms }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-shower"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Full Bathrooms</dt>
                                <dd class="font-semibold">{{ salesOrder.meta?.info?.bathrooms?.full }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-toilet-paper-blank"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Half Bathrooms</dt>
                                <dd class="font-semibold">{{ salesOrder.meta?.info?.bathrooms?.half }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-warehouse"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Garages</dt>
                                <dd class="font-semibold">{{ salesOrder.meta?.info?.garages }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <img v-if="salesOrder.image" class="object-cover"
                        :src="salesOrder.image" :alt="salesOrder.kova?.Name"
                        :title="salesOrder.kova?.Name">
                    <div v-else class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-house"></span>
                    </div>
                </div>

            </div>
        </header>
        <div>
            <div class="block border-b border-oakwood-gray-200">
                <nav class="flex -mb-px px-6 gap-8" role="tablist">
                    <label role="tab" :aria-controls="`panel-${tab.name}`" v-for="tab, i in tabs" :key="`panel-${i}`"
                        :class="selectedTab?.name === tab.name ? 'border-oakwood-blue-500 border-b-2 text-oakwood-blue-600 focus:text-oakwood-blue-800 focus:border-oakwood-blue-700' : 'text-oakwood-gray-500 hover:text-oakwood-gray-700 border-transparent hover:border-oakwood-gray-300 focus:text-oakwood-gray-700 focus:border-oakwood-gray-300'"
                        class="cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none">
                        <input v-model="selectedTab" name="tab" type="radio" :value="tab" class="sr-only">
                        <span class="far mr-2 text-lg" :class="tab.icon"></span>
                        <span class="hidden sm:inline">{{ tab.title }}</span>
                    </label>
                </nav>
            </div>
            <section v-for="tab, i in tabs" :key="`pane-${i}`" :id="`panel-${tab.name}`" role="tabpanel"
                class="py-8 px-5 sm:px-6" :class="selectedTab?.name === tab.name ? 'block' : 'hidden'">
                <InfoPane :salesOrder="salesOrder" @update="updateSalesOrder" v-if="tab.name === 'info'" />
                <ImagePane :salesOrder="salesOrder" v-if="tab.name === 'photos'" />  
                <VideoPane :salesOrder="salesOrder" @update="updateSalesOrder" v-if="tab.name === 'videos'" />
                <InteractivePane :salesOrder="salesOrder" @update="updateSalesOrder" v-if="tab.name === 'interactive'" />
                    
                <template v-if="tab.name === 'debugger'">
                    <h2>Raw Data</h2>
                    <pre>
                        {{ salesOrder }}
                    </pre>
                </template>
            </section>
        </div>
    </div>


</template>

<script>
import axios from "axios";
import { formatToCurrency } from '@/helpers/currencyHelpers';
import LoadingView from '@/components/LoadingView.vue';
import {FormButton, ToggleButton} from '@/components/form';
import {InteractivePane, InfoPane, ImagePane, VideoPane} from '@/components/market_homes';
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";

let tabs = [
    {
        name: 'info',
        icon: 'fa-circle-info',
        title: 'General Information'
    },
    {
        name: 'photos',
        icon: 'fa-images',
        title: 'Images'
    },
    {
        name: 'videos',
        icon: 'fa-video',
        title: 'Videos'
    },
    {
        name: 'interactive',
        icon: 'fa-panorama',
        title: 'Interactives'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    data() {
        
        return {
            salesOrder: {
                id: this.$route.params.id,
                status: null,
                sales_order_rid: null,
                meta: {
                    interactives: [],
                    videos: [],
                    info: {
                        bedrooms: null,
                        bathrooms: {
                            full: null,
                            half: null
                        },
                        garages: null,
                        sqft: null,
                        description: null,
                    }
                },
                created_at: null,
                updated_at: null,
                kova: {},
            },
            isLoading: true,
            tabs: [],
            selectedTab: tabs[0]
        }
    },
    methods: {
        marketHomes() {
            axios.get(`sales_orders/${this.$route.params?.id}`)
                .then(res => {
                    if(res.data.meta === null) delete res.data.meta;
                    res.data.meta = {...this.salesOrder.meta, ...res.data.meta};
                    this.salesOrder = res.data;
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        formatToCurrency,
        changeStatus(toggle) {
            this.salesOrder.status = toggle ? 'active' : 'inactive';
            this.save();
        },
        save() {
            this.isLoading = true;
            axios.patch(`sales_orders/${this.salesOrder.id}`, this.salesOrder)
                .then(res => {
                    console.log(res.data);
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        updateSalesOrder(value) {
            this.salesOrder = value;
            this.save();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user']),
        lot() {
            let lotId = this.salesOrder.kova?.Lot?.LotID;
            if(!lotId) return '-';
            return parseInt(lotId.split('-').pop());
        },
        statusLabel() {
            switch(this.salesOrder.status) {
                case 'active' :
                    return 'Published';
                case 'inactive' :
                    return 'Unpublished';
                case 'new' : 
                default :
                    return 'New';
            }
        }
    },
    created() {
        this.marketHomes();
        this.tabs = tabs.filter(tab => {
            if(this.user.level < 2 && tab.name == 'debugger') return false;
            return true;
        })
    },
    components: {
        LoadingView,
        ToggleButton,
        FormButton,
        InteractivePane,
        VideoPane,
        ImagePane,
        InfoPane
    },
}
</script>@/components/form@/components/market_homes