<template>
    <div v-if="message.isVisible" :class="`rounded-xl ${bgColor.bgColor} p-4 md:mx-8`" id="top_msg">
        <div class="flex">
            <div class="flex-shrink-0">
                <div v-html="bgColor.icon"></div>
            </div>
            <div class="ml-3">
                <h3 v-if="bgColor.text" :class="`text-sm font-medium ${bgColor.textColor}`">
                    {{ bgColor.text }}
                </h3>
                <div :class="`mt-2 text-sm ${bgColor.textColor}`">
                    <p>
                        {{ message.text }}
                    </p>
                    <p v-if="countdown > 0" class="text-xs text-oakwood-gray-500">Automatically closing this message in {{countdown}} seconds</p>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <div @click="closeAlert" class="inline-flex focus:outline-none cursor-pointer">
                        <i class="fa-solid fa-xmark fa-lg" :class="`${bgColor.textColor}`"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MessageDisplay",
    data() {
        return {
            countdownInterval: null,
            countdown: 5
        };
    },
    computed: {
        message() {
            return this.$store.state.message;
        },
        bgColor() {
            if (this.message && this.message.type === 'alert') {
                return {
                    bgColor: 'bg-yellow-50',
                    textColor: 'text-yellow-800',
                    text: 'Alert',
                    icon: '<i class="fa-duotone fa-triangle-exclamation fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #facc15;"></i>'
                };
            } else if (this.message && this.message.type === 'error') {
                return {
                    bgColor: 'bg-oakwood-red-100',
                    textColor: 'text-oakwood-red-800',
                    text: 'Error',
                    icon: '<i class="fa-duotone fa-circle-exclamation fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #f87171;"></i>'
                };
            } else if (this.message && this.message.type === 'success') {
                return {
                    bgColor: 'bg-oakwood-green-100',
                    textColor: 'text-oakwood-green-800',
                    text: 'Success',
                    icon: '<i class="fa-duotone fa-circle-check fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #4ade80;"></i>'
                };
            } else {
                return {
                    bgColor: 'bg-oakwood-blue-100',
                    textColor: 'text-oakwood-blue-700',
                    text: 'Message',
                    icon: '<i class="fa-duotone fa-circle-info fa-lg" style="--fa-primary-color: #ffffff; --fa-secondary-color: #257ceb;"></i>'
                };
            }
        },
    },
    methods: {
        closeAlert() {
            clearInterval(this.countdownInterval);
            this.$store.commit('setMessage', { isVisible: false, text: '', type: '' });
        },
        startCountdown() {
            // Inicia o contador regressivo
            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.closeAlert();
                }
            }, 1000);
        },
    },
    watch: {
        'message.isVisible': {
            handler(newValue) {
                if (newValue) {
                    setTimeout(() => {
                        const element = document.querySelector("#top_msg");
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 100);

                    this.countdown = 5;
                    this.startCountdown();
                } else {
                    clearInterval(this.countdownInterval);
                }
            },
            immediate: true
        }
    },
};
</script>

<style scoped></style>