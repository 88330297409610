<template>
    <a v-if="href" :class="classes" :href="href">
        <span v-if="icon" class="far mr-1" :class="icon"></span><slot></slot>
    </a>
    <router-link v-else-if="to !== false" :to="to" :class="classes">
        <span v-if="icon" class="far mr-1" :class="icon"></span><slot></slot>
    </router-link>
    <button type="button" :class="[classes, (disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer')]" :disabled="disabled" v-else>
        <span v-if="icon" class="far mr-1" :class="icon"></span><slot></slot>
    </button>
</template>

<script>
export default {
    data() {
        return {
            classes : "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        }
    },
    props: {
        href: {
            default: false,
            required: false
        },
        disabled: {
            default: false,
            required: false
        },
        icon: {
            type: [Boolean, String],
            default: false,
            required: false
        },
        to: {
            type: [Boolean, Object],
            default: false,
            required: false
        }
    }
}
</script>