import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        message: {
            isVisible: false,
            text: '',
            type: ''
        }
    },
    mutations: {
        setMessage(state, message) {
            state.message = message;
        }
    }
});
